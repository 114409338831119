.profile-container .card {
    border-radius: 10px;
    /* box-shadow:
        rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
        rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */
    padding: 24px;
    border: 1px solid #e7e7e7;
}

.profile-container .title {
    font-weight: bolder;
    font-size: 16px;
}


