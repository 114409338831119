@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
    touch-action: manipulation;
    @apply text-[14px] 2xl:text-[16px];
}

body {
    touch-action: manipulation;
    overscroll-behavior-y: contain;
}

body {
    margin: 0;
    font-family: "Inter", sans-serif;
    color: #090c05;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-family: "Inter", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "Inter";
    src: url("./assets/fonts/Inter-Regular.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("./assets/fonts/Inter-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("./assets/fonts/Inter-Medium.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("./assets/fonts/Inter-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: "Inter";
    src: url("./assets/fonts/Inter-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
}

.font-InterLight {
    font-family: Inter;
    font-weight: 300;
}

.font-Inter {
    font-family: Inter;
}

.font-InterMedium {
    font-family: Inter;
    font-weight: 500;
}

.font-InterSemiBold {
    font-family: Inter;
    font-weight: 600;
}

.font-InterBold {
    font-family: Inter;
    font-weight: 700;
}

.box-Shadow {
    box-shadow: 1px 1px 10px 0px #dddddd;
}

.box-Shadow1 {
    /* box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.16); */
    box-shadow: 0px 4px 34px 0px rgba(0, 0, 0, 0.16);
}

.box-Shadow2 {
    box-shadow: 6.27px 6.27px 10.27px 0px rgba(3, 34, 37, 0.24);
}

.box-Shadow3 {
    box-shadow: -10px 12px 34px 0px rgba(48, 98, 254, 0.15);
}

.box-shadow4 {
    box-shadow: -10px 12px 34px 0px rgba(0, 0, 0, 0.15);
}

.box-Shadow-a {
    box-shadow: 0 0 0 10px #deebe9;
}

.slick-slider {
    width: inherit;
    max-width: 100%;
}

.custom-p35 .slick-list {
    padding-bottom: 35px;
}

.custom-p35 .slick-list {
    @apply overflow-visible;
}

.custom-p35 .slick-dots li button:before {
    display: none;
}

.custom-p35 .slick-dots li button {
    width: 12px;
    height: 12px;
    background-color: #e8e7e7;
    padding: 0;
    border-radius: 50%;
}

.custom-p35 .slick-dots li.slick-active button {
    background-color: #090c05;
}

.custom-p35 .slick-dots li {
    width: 16px;
    margin: 0 4px;
}

.selector {
    width: 100% !important;
    border-radius: 40px !important;
    border: 1px solid #e8e7e7 !important;
    background: var(--main-white, #fff) !important;
    padding: 15px 18px 15px 24px !important;
}

.selectorClass {
    width: 100% !important;
    border-radius: 16px !important;
    border: 1px solid #e8e7e7 !important;
    background: var(--main-white, #fff) !important;
    padding: 15px 18px 15px 24px !important;
}

.MuiFormLabel-root {
    position: absolute !important;
    top: 50% !important;
    left: 28% !important;
    transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
    .custome-width .slick-slide {
        width: 200px !important;
        /* or any other width value for mobile */
    }
}

.text-wrap {
    text-wrap: nowrap !important;
}

.scrolling-words-container {
    /* display: flex; */
    align-items: center;
    /* font-size: 2rem; */
    /* font-weight: 600; */
}

.scrolling-words-box {
    height: 88px;
    margin: auto;
    overflow: hidden;
    vertical-align: middle;
}

.scrolling-words-box ul {
    margin: 0 0.625rem;
    padding: 0;
    animation: scrollUp 6s infinite;
    animation-direction: reverse;
}

.scrolling-words-box ul li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 88px;
    list-style: none;
    margin-top: 8px;
}

@keyframes scrollUp {
    15%,
    25% {
        transform: translateY(-20%);
    }

    40%,
    50% {
        transform: translateY(-40%);
    }

    65%,
    75% {
        transform: translateY(-60%);
    }

    90%,
    100% {
        transform: translateY(-80%);
    }
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.border-top-radius-35 {
    border-top-left-radius: 35px;
    border-top-right-radius: 35px;
}

.blur {
    filter: blur(5px);
}

.introduction-video {
    border-radius: 15px;
    scale: 1;
    transition: all 1s cubic-bezier(0.72, 0, 0.12, 1) 0s;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    top: -30px;
}

.scaled {
    scale: 1 !important;
}

.top-center {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}

.introduction-back {
    width: 100%;
    height: 320px;
    opacity: 1;
    scale: 1;
    border-radius: 15px;
    position: relative;
    transition: all 1s cubic-bezier(0.72, 0, 0.12, 1) 0s;
    background-image: url(@/assets/image/Home_Back.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (min-width: 540px) {
    .introduction-back {
        height: 420px;
    }
}

@media (min-width: 768px) {
    .introduction-back {
        height: 480px;
    }

    .scaled {
        scale: 0.9 !important;
    }

    .top-center {
        top: 50% !important;
        left: 50% !important;
        transform: translate(-55%, -55%) !important;
    }
}

@media (min-width: 1024px) {
    .introduction-back {
        height: 600px;
    }

    .introduction-video {
        top: -50px;
    }
}

@media (min-width: 1280px) {
    .introduction-back {
        height: 800px;
    }

    .introduction-video {
        top: -60px;
    }
}

@media (min-width: 1536px) {
    .introduction-back {
        height: 1000px;
    }

    .introduction-video {
        top: -80px;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
        filter: blur(1);
    }

    100% {
        opacity: 1;
        filter: blur(0);
    }
}

.slide-animation {
    /* Define your animation properties here */
    /* Example animation properties */
    animation-name: slide;
    animation-duration: 1s;
}

@keyframes slide {
    /* Define your animation keyframes here */
    /* Example keyframes */
    from {
        /* Initial state of the slide */
        opacity: 0;
        transform: translateX(-100%);
    }

    to {
        /* Final state of the slide */
        opacity: 1;
        transform: translateX(0);
    }
}

.App {
    position: relative;
    text-align: center;
}

#logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.slideUpShow {
    opacity: 0;
    animation: slideUpShow ease 2.5s forwards 0.5s;
}

.slideUpHide {
    opacity: 1;
    animation: slideUpHide ease 1.5s forwards 0.5s;
}

@keyframes slideUpHide {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }

    70% {
        transform: translateY(-50px);
        opacity: 0;
    }

    100% {
        transform: translateY(-50px);
        opacity: 0;
    }
}

@keyframes slideUpShow {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.hide {
    opacity: 0;
}

.Navbar {
    position: relative;
}

.Navbar__inner-content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-align-items: center;
    align-items: center;
    display: none;
    height: 9.6rem;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    max-width: 1680px;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    top: 0;
    z-index: 12;
}

@media screen and (min-width: 1280px) {
    .Navbar__inner-content {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
}

.Navbar__actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.Navbar__actions .button:last-child {
    min-width: 178px;
}

.Navbar__action {
    border-top-width: 0;
    display: inline-block !important;
    margin-bottom: 0;
    margin-right: 0.4rem;
}

.Navbar__skip-link {
    position: absolute;
    top: calc(9.6rem + 16px);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
}

.Navbar__skip-link:focus {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.Navbar__logo-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@media screen and (min-width: 1280px) {
    .Navbar__logo-wrapper {
        padding-right: 16px;
    }
}

.Navbar__menu {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-align-items: stretch;
    align-items: stretch;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    list-style: none;
    margin-bottom: 0;
    margin-left: 0;
}

.Navbar__menu-item {
    -webkit-box-flex: 0;
    display: flex;
    align-items: center;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    font-weight: 600;
    padding: 0 20px;
    border-radius: 10px;
    text-align: center;
}

.navbar-link {
    @apply font-Inter text-gray-950 font-normal text-base xl:text-[14px] 2xl:text-[18px] leading-[1.4] 2xl:leading-[1.2] cursor-pointer;
}

.navbar-link-invert {
    @apply text-white;
}

.Navbar__menu-item[hidden] {
    display: none;
}

.Navbar__menu-item a:hover {
    color: #0a85ea;
    cursor: pointer;
}

.prospecting .Navbar__menu-item:hover {
    background-color: rgba(255, 255, 255, 0.25);
}

.emailverification .Navbar__menu-item:hover {
    background-color: #fadeff;
}

.csvexport .Navbar__menu-item:hover {
    background-color: #c7ccff;
}

.advancedsearch .Navbar__menu-item:hover {
    background-color: #99ffb2;
}

.integration .Navbar__menu-item:hover {
    background-color: #fdd8c5;
}

.competitors .Navbar__menu-item:hover {
    background-color: #ffeb99;
}

.blog .Navbar__menu-item:hover {
    background-color: #fff;
}

.blog .Navbar__menu-item:hover .navbar-link {
    color: #090c05;
}

.sticky .Navbar__menu-item:hover {
    background-color: #f1f1f1;
}

.Navbar__menu-item--is-megamenu-trigger:after {
    background-color: #383838;
    bottom: 0;
    content: "";
    display: block;
    height: 4px;
    margin-top: -4px;
    position: relative;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: width;
    transition-property: width;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
    width: 0;
}

.Navbar__menu-item--is-megamenu-trigger.Navbar__menu-item--active:focus-within:after,
.Navbar__menu-item--is-megamenu-trigger:hover:after {
    width: 100%;
}

.Navbar__menu-item--is-megamenu-trigger.Navbar__menu-item--active:focus-within .Navbar__mega-menu,
.Navbar__menu-item--is-megamenu-trigger:hover .Navbar__mega-menu {
    display: block;
}

.Navbar__menu-item--is-megamenu-trigger.Navbar__menu-item--active:focus-within .Navbar__menu-item-arrow,
.Navbar__menu-item--is-megamenu-trigger:hover .Navbar__menu-item-arrow {
    display: inline-block;
    opacity: 0.9;
    -webkit-transform: rotate(-180deg);
    -ms-transform: rotate(-180deg);
    transform: rotate(-180deg);
}

.Navbar__menu-item-anchor {
    font-weight: 600;
    line-height: 9.6rem;
}

.Navbar__menu-item-anchor:after {
    display: none;
}

.Navbar__menu-item-anchor:hover {
    cursor: default;
}

.Navbar__menu-item-arrow {
    margin-left: 0;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property:
        transform,
        -webkit-transform;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

.Navbar__mega-menu {
    background-color: #fff;
    -webkit-box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 16px 24px 0 rgba(0, 0, 0, 0.08);
    display: none;
    /* left: 0; */
    border-radius: 8px;
    outline: 1px solid #c5c5c5;
    position: absolute;
    /* top: 96px; */
    /* width: 100%; */
    z-index: 1000001;
}

.MainLogo_Pos {
    position: absolute;
    left: 3rem;
    top: 1.5rem;
    height: 2rem;
}

/*Auth pages*/

.auth-form {
    @apply flex flex-col gap-4;
}

.auth-title {
    @apply font-InterBold flex w-full text-3xl leading-[1.2] font-bold;
}

.auth-description {
    @apply font-InterLight font-light text-base leading-[1.4] flex w-full;
}

.auth-label {
    @apply font-InterMedium text-base flex w-full font-medium;
}

.auth-input-container {
    @apply relative flex w-full flex-row items-center border-[1px] border-stone-200 bg-white rounded-lg;
}

.auth-input {
    @apply font-InterLight w-full rounded-lg px-6 py-3 text-[16px] font-light placeholder-[#4f4f4f] placeholder:opacity-40 outline-none;
}

.auth-error {
    @apply font-normal text-xs text-red-500 absolute;
}

.submit-button {
    @apply text-base font-Inter font-normal  flex h-[48px] w-full leading-[1.2] tracking-[0.32px] items-center justify-center rounded-lg bg-[#090c05] px-[24px] text-white;
}

.google-button {
    --tw-shadow: none;
    @apply text-base font-Inter flex h-[48px] leading-[1.2] tracking-[0.32px] w-full items-center justify-center gap-3 rounded-lg border border-[#e7e7e7] bg-white uppercase text-stone-950;
}

/*Feedback slider*/

.feedback-slider-wrapper {
    @apply !h-auto box-border relative !px-4 !py-10 !py-20 !overflow-x-clip !overflow-y-visible;
}

@media (min-width: 1024px) {
    /* This is what the `lg:` prefix corresponds to in Tailwind */
    .feedback-slider-wrapper {
        @apply !py-20;
    }
}

.feedback-slide {
    @apply !h-auto box-border relative;
}

@media (min-width: 1536px) {
    /* This is the equivalent of `2xl:` in Tailwind */
    .feedback-slide {
        @apply !w-[710px];
    }
}

.double-quote-icon {
    @apply absolute w-[77px] h-[68px] lg:w-[50px] lg:h-[45px] z-50 bottom-[95%] right-[32px] lg:left-[190px];
}

.swiper-pagination {
    @apply !top-[110%];
}

.swiper-pagination-bullet {
    @apply !w-4 !h-4 !bg-stone-250 !opacity-100;
}

.swiper-pagination-bullet-active {
    @apply !bg-[#0D0D0D];
}

.custom-text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-shrink: 1;
    min-width: 0;
}

/*Main-page statistic*/

.statistic-count-container {
    @apply flex flex-col items-center justify-center xl:items-start gap-2;
}

@media (max-width: 1280px) {
    /* Corresponds to max-xl in Tailwind */
    .statistic-count-container {
        @apply py-6 border-b last:border-b-0;
        border-color: rgba(9, 12, 5, 0.07);
    }
}

.statistic-count-title {
    @apply font-InterMedium leading-[1.1] text-black 2xl:text-[40px] text-[32px] font-medium xl:font-bold;
}

.statistic-count-description-container {
    @apply flex gap-4 items-center justify-center xl:justify-start text-black;
}

.statistic-count-description {
    @apply text-black text-[18px] md:text-[22px] 2xl:text-[27px] font-InterMedium font-medium lg:font-normal leading-[1.2];
}

.statistic-count-icon {
    @apply h-[20px] w-[20px] lg:hidden;
}

/*landing CTA*/

.kompassai-cta-item {
    @apply flex flex-col items-center gap-4;
}

.kompassai-cta-item-title {
    @apply text-center text-[24px] lg:text-[22px] 2xl:text-[28px] font-bold leading-[1.2] text-stone-950;
}

.kompassai-cta-item-description {
    @apply text-center text-base 2xl:text-xl leading-[1.4] text-stone-950;
}

/*Landing get started*/

.kompassai-get-started-button {
    @apply flex items-center whitespace-nowrap justify-center rounded-lg font-InterBold font-bold leading-[1.2] tracking-[0.32px] py-3 px-4 text-sm w-full max-w-[200px];
}

/*Perfect lead*/
.perfect-lead-label {
    @apply text-center text-white whitespace-nowrap bg-black rounded-full py-2.5 px-4 flex items-center justify-center max-lg:mx-auto w-fit font-InterMedium text-[14px] lg:text-[16px] 2xl:text-[20px] mb-4 lg:mb-8;
}

.perfect-lead-title {
    @apply text-black font-InterBold text-[28px] lg:text-[44px] 2xl:text-[54px] max-lg:text-center leading-[1.2];
}

.perfect-lead-item-title {
    @apply font-InterBold text-left text-[28px] lg:text-[32px] 2xl:text-[40px] text-stone-950;
}

.perfect-lead-item-description {
    @apply font-Inter text-[16px] lg:text-[20px] 2xl:text-[24px] leading-[1.4] text-primary-950 mt-4;
}

.perfect-lead-item-button {
    @apply mt-5 inline-block lg:mt-16 rounded-md bg-stone-950 text-white py-4 px-6 lg:py-2 lg:px-4 hover:bg-blue-500 font-InterMedium text-[16px];
}

.rs-affix {
    z-index: 101 !important;
}

.custom-shadow {
    box-shadow:
        0px 5px 15px 0px rgba(0, 0, 0, 0.12),
        0px 15px 35px 0px rgba(103, 110, 118, 0.08);
}

.main-title {
    @apply font-Inter text-[32px] md:text-[44px] lg:text-[50px] xl:text-[56px] 2xl:text-[64px] text-stone-950 text-center font-bold leading-[1.1];
}

.main-subtitle {
    @apply text-stone-950 font-Inter font-bold text-[28px] md:text-[36px] lg:text-[44px] xl:text-[52px] 2xl:text-[60px] leading-[1.2];
}

#mainButton #firstIcon,
#changeButton #firstIcon {
    opacity: 1;
    position: absolute;
    right: 1rem;
    transition:
        right 1.4s cubic-bezier(0.23, 1.2, 0.32, 1),
        opacity 1.4s cubic-bezier(0.23, 1.2, 0.32, 1);
}

#mainButton:hover #firstIcon,
#changeButton:hover #firstIcon {
    opacity: 0;
    right: -0.2rem;
}

#mainButton #secondIcon,
#changeButton #secondIcon {
    position: absolute;
    opacity: 0;
    right: 1.2rem;
    transition:
        right 1.4s cubic-bezier(0.23, 1.2, 0.32, 1),
        opacity 1.4s cubic-bezier(0.23, 1.2, 0.32, 1);
}

#mainButton:hover #secondIcon,
#changeButton:hover #secondIcon {
    opacity: 1;
    right: 1rem;
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.4);
    }

    100% {
        transform: scale(1);
    }
}

.main-loader {
    animation: scaleAnimation 2s infinite;
}

.main-loader-wrapper {
    height: calc(100vh - 140px);
}

@keyframes opacityAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.kompass-animation {
    opacity: 0;
    animation: opacityAnimation 2s ease-in forwards;
}

.main-button {
    @apply px-[12px] py-[6px] bg-[#2D7A89] text-white text-base font-bold capitalize rounded-[8px] flex items-center justify-center gap-2 shrink-0;
}

.lottie-wrapper [role="button"] {
    cursor: auto;
}

.topbar-hero {
    min-height: calc(100vh - 175px);
}

.competitors-box {
    box-shadow:
        0px 150px 42px 0px rgba(128, 128, 128, 0),
        0px 96px 39px 0px rgba(128, 128, 128, 0.01),
        0px 54px 32px 0px rgba(128, 128, 128, 0.05),
        0px 24px 24px 0px rgba(128, 128, 128, 0.09),
        0px 6px 13px 0px rgba(128, 128, 128, 0.1);
}

.blog-post {
    @apply font-Inter text-stone-950 text-[16px] md:text-[18px] lg:text-[20px] leading-[1.4] list-decimal;
}

.blog-post ul {
    list-style: disc;
    padding-left: 22px;
    margin-top: 8px;
}

.blog-post ol {
    list-style: decimal;
    padding-left: 22px;
    margin-top: 8px;
}

@media (min-width: 1024px) {
    .search-table {
        height: calc(100vh - 81px);
    }
}

.advanced-search-filters-sub-title {
    @apply text-[12px] text-stone-350 font-semibold leading-[1.2];
}

.filters-accordion-title {
    @apply !font-medium !leading-[1.2] !text-[16px];
}

@media (min-width: 1536px) {
    /* Corresponds to 2xl in Tailwind */
    .filters-accordion-title {
        @apply !text-[20px];
    }
}

.advanced-filters-grid-item {
    @apply flex flex-col gap-[16px] gap-y-2 px-[20px] py-[24px] 2xl:px-[24px] 2xl:py-[30px];
}

.advanced-filters-group-box {
    @apply border border-stone-250 rounded-[8px];
}

/* ai-engagement */
.campaign-card {
    @apply border border-[#E7E7E7] p-[24px] rounded-[8px];
}

.campaign-card-input {
    @apply font-Inter w-full border border-[#E8E7E7] bg-stone-100 p-2 text-sm rounded-md outline-none;
}

.campaign-card-suggestion {
    @apply bg-[#F4F3F0] py-[8px] px-[16px] rounded-[4px] font-Inter font-medium text-[14px] text-accents-mainBlack flex-shrink-0;
}

.no-spinner-input::-webkit-outer-spin-button,
.no-spinner-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.no-spinner-input {
    -moz-appearance: textfield;
}

.custom-scrollbar-section::-webkit-scrollbar {
    width: 14px;
}

.custom-scrollbar-section::-webkit-scrollbar-thumb {
    background-color: #d1d1d1;
    border-radius: 4px;
    box-shadow: inset -6px 0 0 0px white;
    /* Simulate a 6px "border-right" effect */
}

.custom-scrollbar-section::-webkit-scrollbar-track {
    background-color: transparent;
    margin: 10px;
}

.p-reserved {
    white-space: pre-wrap;
}

.triangle-search {
    position: relative;
    box-sizing: border-box;
}

.triangle-search::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 0;
    right: 0px;
    box-sizing: border-box;

    border: 1em solid #e8e7e7;
    border-color: transparent transparent white white;

    transform-origin: 0 0;
    transform: rotate(135deg);

    box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.12);
}

.loader-circle {
    width: 16px;
    height: 16px;
    background-image: url("/src/assets/image/loader.png");
    background-size: contain;
    background-repeat: no-repeat;
    animation: spin 2s linear infinite;
    display: inline-block;
    margin-right: 8px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loader-dots-wrapper {
    display: inline-flex;
    align-items: baseline;
    gap: 2px;
}

.loader-dots {
    width: 12px;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side, #000 50%, #0000) 0 / calc(100% / 3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: l1 2s steps(4) infinite;
}
@keyframes l1 {
    to {
        clip-path: inset(0 -34% 0 0);
    }
}

@keyframes dotAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* ai-warmup */

.email-warmup-subtitle {
    @apply pt-8 pb-3 font-normal text-[14px] 2xl:text-[18px] 2xl:pb-6 2xl:pt-10 text-gray-950 hover:text-accents-blue2 focus:text-accents-blue2 transition-colors;
}

.warmup-search-input {
    background-image: url("/src/assets/image/down-chevron.svg");
    background-repeat: no-repeat;
    background-position: right 0.7rem top 50%;
    background-size: 0.65rem auto;
}

.warmup-email-status-text {
    @apply px-2 py-1 flex items-center justify-center w-[75px] rounded-[30px];
}

.warmup-filter-button {
    @apply font-normal text-[14px] leading-[1.4] rounded-[8px] py-[10px] px-[16px];
}

.warmup-email-settings-statistic {
    @apply font-normal text-[12px] text-black;
}

.warmup-divider-dot {
    @apply w-[8px] h-[8px] rounded-full bg-[#D4D4D4];
}

.warmup-frequency-item-container {
    @apply flex justify-between;
}

.warmup-frequency-title {
    @apply font-semibold text-[20px] 2xl:text-[24px] text-accents-blue2;
}

.warmup-frequency-number-input {
    @apply font-semibold text-[20px] 2xl:text-[24px] text-accents-blue2 focus:text-black w-[50px];
}

.warmup-frequency-description {
    @apply font-normal text-[14px] text-stone-350;
}

.warmup-edit-frequency {
    @apply w-[24px] h-[24px] text-zinc-900 hover:text-accents-blue2 focus:text-accents-blue2 transition-colors;
}

.warmup-time-setup-title {
    @apply text-sm font-InterSemiBold text-black leading-[1.4];
}

.quantity-button {
    @apply text-[16px] border border-accents-blue2 text-accents-blue2 rounded-full w-[24px] h-[24px] flex justify-center items-center focus:text-white focus:bg-accents-blue2 hover:text-white hover:bg-accents-blue2 transition-colors;
}

.warmup-title-text {
    @apply text-black text-[20px] 2xl:text-[24px] font-semibold font-InterBold;
}

.tab_conatiner_ma {
    height: calc(100vh - 80px - 200px);
}

.mail_count {
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #4873fa;
    color: #4873fa;
    width: 34px;
    height: 34px;
    margin-left: auto;
    margin-right: auto;
}

.mail_status {
    border-radius: 30px;
    background-color: #ffe0d1;
    color: #b86c49;
    width: max-content;
    padding: 5px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 2;
}

.m_box_health {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    column-gap: 2px;
}

.m_box_health span {
    width: 10px;
    padding: 1px;
    background-color: #e8e7e7;
}

.m_box_health span.active {
    background-color: #e5322b;
}

.m_box_health span.red {
    background-color: #e5322b;
}

.m_box_health span.orange {
    background-color: #ffac1c;
}

.m_box_health span.green {
    background-color: #66ff00;
}

.mail_service {
    display: flex;
    align-items: center;
    column-gap: 6px;
    padding: 6px 12px;
    margin-bottom: 10px;
    cursor: pointer;
}

.mail_service.active {
    background: #e8e7e7;
    border-radius: 4px;
}

.scroll-bar-thin {
    scrollbar-width: thin;
}

.mail_cover {
    display: flex;
    align-items: start;
    column-gap: 12px;
}

.mail_cover span {
    height: 40px;
    width: 40px;
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border-radius: 50%;
    text-transform: capitalize;
}

.mail_cover img {
    height: 40px;
    width: 40px;
}

.mail_cover_unread {
    height: 10px;
    width: 10px;
    display: block;
    background-color: #4873fa;
    border-radius: 100%;
}

::-webkit-scrollbar {
    width: 5px;
}

.mail_cover .sender {
    font-size: 16px;
    font-weight: 600;
}

.mail_cover .title {
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    word-break: break-word;
}

.mail_content_conatiner {
    height: auto;
    overflow-y: scroll;
    scrollbar-width: thin;
}

.mailList_container {
    height: calc(100vh - 180px);
    overflow-y: scroll;
    scrollbar-width: thin;
}

.mailList_container_small {
    height: calc(100vh - 240px);
    overflow-y: scroll;
    scrollbar-width: thin;
}

.mail_title {
    width: 75%;
}

.mail_title_text {
    width: 100%;
}

.ck.ck-editor {
    width: 100% !important;
}

.ck-content {
    height: calc(100vh - 550px);
    overflow-y: scroll;
    scrollbar-width: thin;
}

.calendar-rediect {
    filter: drop-shadow(0px 100px 0 #1976d2);
    transform: translateY(-100px);
}

.no_mail_found {
    height: calc(100vh - 140px);
}

.MuiTableSortLabel-root {
    white-space: nowrap !important;
}

.css-1oqao0q-MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    min-width: 0 !important;
}

.inbox .react-datepicker-wrapper {
    width: 100%;
}

.main-text-black-500 {
    font-size: 14px;
    font-weight: 500;
    font-family: Inter;
    color: #050505;
}

.main-text-black-600 {
    font-size: 14px;
    font-weight: 600;
    font-family: Inter;
    color: #050505;
}

/* hide number arrows - Chrome, Safari, Edge, Opera */
.opportunity-amount::-webkit-outer-spin-button,
.opportunity-amount::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.opportunity-amount {
    -moz-appearance: textfield;
}

.triangle-search.bottom-triangle::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: auto;
    bottom: -25px;
    right: 20px;
    box-sizing: border-box;
    border: 1em solid #e8e7e7;
    border-color: white white transparent transparent;
    transform-origin: 0 0;
    transform: rotate(135deg);
    box-shadow: 1px -1px 1px 0 rgba(0, 0, 0, 0.12);
}

.recaptcha-container {
    /* The container should have enough space to contain the scaled widget */
    width: 635px; /* or whatever width you want the final widget to appear */
    height: auto; /* or a fixed height that accommodates your scale */

    /* Hide overflow so you don't see partial scrollbars from the iframe */
    overflow: hidden;
    position: relative; /* so that transform scaling doesn't shift the element unexpectedly */
}

/* The recaptcha itself: scale up from the default ~304px wide to ~635px wide */
.g-recaptcha {
    transform: scale(2.08); /* 304 * 2.08 ≈ 632.32, close to 635px */
    transform-origin: 0 0; /* Keep the widget pinned to the top-left while scaling */
    -webkit-transform: scale(2.08); /* for older Safari compatibility, if needed */
    -webkit-transform-origin: 0 0;
}

.custom-legend {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    margin-top: 0.5rem;
}

.custom-legend-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.custom-legend-circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
}

.head-count-donut-chart {
    z-index: 9999 !important;
}
/* Example Tailwind CSS for the LoadingIndicator */
.animate-bounce {
    animation: bounce 1s infinite;
}

.delay-200 {
    animation-delay: 0.2s;
}

.delay-400 {
    animation-delay: 0.4s;
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-8px);
    }
}

.custom-toast-container {
    display: flex !important;
    align-items: center !important;
}

.custom-toast-container .Toastify__close-button {
    position: relative !important;
    top: auto !important;
    right: auto !important;
    align-self: center !important;
    margin-left: 8px !important;
}

.custom-toast-container .Toastify__close-button > svg {
    color: #888888 !important;
    width: 16px !important;
    height: 16px !important;
}

.Toastify__toast-container {
    position: fixed !important;
    right: 24px !important;
    width: auto !important;
    height: 56px !important;
    min-width: fit-content !important;
    max-width: calc(100vw - 48px) !important;
}

.Toastify__toast {
    padding: 0 16px !important;
    height: 56px !important;
    min-height: 56px !important;
    max-height: 56px !important;
    min-width: 300px !important;
    max-width: none !important;
    width: fit-content !important;
    margin-left: auto !important;
}

.toast-message {
    white-space: nowrap !important;
}

.Toastify__toast-icon {
    display: none !important;
}

/* Specific styles for wait toast */
.wait-toast-container {
    height: 78px !important;
    min-height: 78px !important;
    max-height: 78px !important;
    display: flex !important;
    align-items: center !important;
}

.wait-toast-container .Toastify__close-button {
    position: relative !important;
    top: 18px !important;
    right: auto !important;
    align-self: flex-start !important;
    margin-left: 8px !important;
}

.main-white-button {
    @apply flex cursor-pointer flex-row gap-1 rounded-lg py-1.5 px-3 text-sm font-medium border whitespace-nowrap border-stone-250 font-Inter text-[#050505] leading-[1.4] hover:bg-dgray-50 transition-all;
}

.main-black-button {
    @apply flex cursor-pointer flex-row gap-1 rounded-lg py-1.5 px-3 text-sm font-medium whitespace-nowrap bg-black  text-white font-Inter leading-[1.4] hover:opacity-80 transition-all;
}
